<template>
    <van-dialog v-model="show" title="注意事项"
                class="service-dialog"
                show-cancel-button
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="$emit('confirmRule')"
                @cancel="$emit('refuseRule')">
        <div v-if="type === 1">
            <p class="rule">1、店主在发布信息前，需绑定店铺</p>
            <p class="rule">2、店主发布信息时，可以注明探店时间和探店内容要求</p>
            <p class="rule">3、店主发布信息后，如果48小时内没有人接单，则发单超时，订单将自动被系统撤销，探店次数返还到店主的账户余额里，店主可重新发布探店信息</p>
            <p class="rule">4、信息发布后，如果有人成功抢单，店主可通过页面实时查询探店进度</p>
            <p class="rule">5、达人成功抢单后超出店主规定的探店时间48小时没有成功上传内容，则系统将自动撤销订单，探店次数返还到店主的账户余额里，店主可重新发布探店信息</p>
            <p class="rule">6、店主发布信息后，在达人尚未成功投稿时，可联系人工在线客服手动撤销订单，撤销后，探店次数返还到店主的账户余额里，店主可重新发布探店信息</p>
            <p class="rule">7、店主不可以在本平台发布违法或违禁商品信息</p>
            <p class="rule">8、来飞网是一个培训店主如何做店铺运营的平台。店主可以学习课程，召回客户补发点评，发布霸王餐活动。平台仅提供信息发布的网络技术服务，如因发布违规内容造成账号降权，我司不承担相应责任</p>
        </div>

        <div v-if="type === 2">
            <p class="rule">1、霸王餐采用抽奖的形式发布，系统自动匹配同城的达人进行抽奖</p>
            <p class="rule">2、店主不得发布虚假体验项目，不得在达人到店时，额外向达人收取隐性消费费用</p>
            <p class="rule">3、达人体验了店主的产品或服务后，将写真实体验后后点评</p>
            <p class="rule">4、建议店主在每天9:00-20:00期间发布霸王餐活动，参与人数较多</p>
            <p class="rule">5、发布霸王餐前，请再次确认店铺城市和地址是否正确。在“首页-我的店铺”可对店铺地址进行修改</p>
            <p class="rule">6、霸王餐每期抽奖活动持续时间为1小时，中奖的达人将在14天内光临您的店铺并提交点评</p>
            <p class="rule">7、达人到店后，请店主在“首页-活动进度-霸王餐”对达人进行身份验证并点击“确认到店”按钮</p>
        </div>

        <div v-if="type === 3">
            <p class="rule">1、来飞网通过定位技术校验达人是否曾经到店的真实度。如未检测到达人有到店位置信息，则禁止接单。达人不得夸大点评内容，不得恶意差评</p>
            <p class="rule">2、达人抢单后需在48小时内完成点评并提交点评截图，否则系统将自动撤回订单</p>
            <p class="rule">2、达人投稿后，平台的工作人员会在后台验收达人提交的点评内容。如果验收通过，公众号会在达人投稿后的第3天自动微信红包结算；如果验收不通过，则需达人重新点评重新提交点评截图</p>
            <p class="rule">4、结算时，平台会向达人收取稿费金额的10%，作为使用平台的信息技术服务费用</p>
            <p class="rule">5、来飞网是一个培训店主如何做店铺运营的平台。店主可以学习课程，召回客户补发点评，发布霸王餐活动。平台仅提供信息发布的网络技术服务，如因发布违规内容造成账号降权，我司不承担相应责任</p>
        </div>

<!--        霸王餐参与-->
        <div v-if="type === 4">
            <p class="rule">1、霸王餐活动是店主发起的到店免费体验项目</p>
            <p class="rule">2、参与霸王餐的达人仅限与店铺地址同城</p>
            <p class="rule">3、达人中奖霸王餐后需在14天内到店并使用霸王餐，14天后将失效</p>
            <p class="rule">4、到店后需联系店主，并出示中奖信息。到店体验后，需写消费后点评</p>
            <p class="rule">5、中奖后的达人，将受到公众号消息通知。或在中奖公告查看中奖信息</p>
            <p class="rule">6、来飞网是一个培训店主如何做店铺运营的平台。店主可以学习课程，召回客户补发点评，发布霸王餐活动。平台仅提供信息发布的网络技术服务，如因发布违规内容造成账号降权，我司不承担相应责任</p>
        </div>

    </van-dialog>
</template>

<script>
    export default {
        name: "ExploreService",
        data(){
            return {
                show: false,
                type: 1, // 1:商家购买探店、2：商家购买霸王餐、3：达人探店
            }
        },
        methods:{
            init(type = 1){
                this.type = type;
                this.show = true;
            }
        }
    }
</script>

<style scoped>
    .title{
        font-weight: bold;
    }
    .rule{
        margin: 3px;
        font-size: 14px;
    }
</style>
<style>
    .service-dialog .van-dialog__content{
        max-height: 400px;
        overflow: auto;
        padding: 10px;
    }
</style>