<template>
    <div>
        <div style="margin-bottom: 50px;">
            <van-image src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/buy3.png"
                       style="display: block;"></van-image>
            <div style="font-size: 15px;position: relative;margin-top: -30px;">
                <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;margin: 10px;padding: 10px 14px;">
                    <div style="font-weight: bold;margin-top: 10px;display: flex;font-size: 15px;">
                        <van-image :src="asset" height="15" width="10" style="margin: auto 10px auto 0"></van-image>
                        <span>店铺缺少客流怎么办</span>
                    </div>
                    <p>来飞网平台吸引了大量的美团点评活跃用户。中奖霸王餐的用户将会到店体验您的活动项目。您还可以向到店用户推荐其他好的产品，实现额外收益</p>
                    <div style="font-weight: bold;margin-top: 10px;display: flex;font-size: 15px;">
                        <van-image :src="asset" height="15" width="10" style="margin: auto 10px auto 0"></van-image>
                        <span>增加团购交易，提升搜索排名</span>
                    </div>
                    <p>中奖霸王餐的用户将会下单购买参与活动的团购产品。用户完成探店后再全额返还</p>
                    <div style="font-weight: bold;margin-top: 10px;display: flex;font-size: 15px;">
                        <van-image :src="asset" height="15" width="10" style="margin: auto 10px auto 0"></van-image>
                        <span>消费后点评，快速提升星级</span>
                    </div>
                    <p>因为是先团购后点评，每条点评会加「消费后点评」标签，此类点评对于星级评分的权重更高</p>
                </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin: 20px;">
                <van-image :src="title" style="flex: 0 0 auto;height: 3px;width: 70px;"></van-image>
                <span style="flex: 0 0 auto;margin: 0 10px;font-size: 20px;font-weight: bold;">活动流程</span>
                <van-image :src="title"
                           style="flex:0 0 auto;height: 3px;width: 70px;transform: rotate(180deg);-o-transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);filter:progid:DXImageTransform.Microsoft.BasicImage(Rotation=2);"></van-image>
            </div>
            <div class="progress">
                <p>1. 店主在我的店铺里添加要参与霸王餐活动的团购项目</p>
                <p>2. 发布霸王餐活动</p>
                <p>3. 来飞网自动匹配与店铺所处同一个商圈的用户，并推送活动</p>
                <p>4. 用户参与抽取霸王餐</p>
                <p>5. 中奖用户下单购买指定团购并到店体验</p>
                <p>6. 体验后店主核销用户的团购券</p>
                <p>7. 用户写体验后的点评，店主返款</p>
            </div>
            <div style="height: 80px;width: 100%;"></div>
        </div>
        <van-goods-action>
            <van-goods-action-button color="linear-gradient(to top,rgb(108,82,169),rgb(173,140,245))"
                                     style="border-radius: 999px;" type="danger" text="立即购买" @click="buyBefore"/>
        </van-goods-action>

        <van-sku
                v-model="show"
                :sku="sku"
                :goods="good"
                :hide-stock="true"
                :initial-sku="initialSku"
                :close-on-click-overlay="true"
                :show-add-cart-btn="false"
                @buy-clicked="onBuyClicked">
            <template slot="sku-header" slot-scope="props">
                <div></div>
            </template>
            <template slot="extra-sku-group" slot-scope="props">
                <van-cell title="服务单价">
                    <div class="van-sku__goods-price">
                        <span class="van-sku__price-num" style="float: right;">{{props.price}}</span>
                        <span class="van-sku__price-symbol" style="float: right;">￥</span>
                    </div>
                </van-cell>
            </template>
            <template slot="sku-actions" slot-scope="props">
                <div class="van-sku-actions">
                    <span style="display:inline-block;line-height: 41px;min-width: 40px;color: #ee0a24;font-size: 15px;">合计:</span>
                    <div class="van-sku__goods-price" style="line-height: 40px;margin-right: 16px;">
                        <span class="van-sku__price-num">￥{{(props.price * props.selectedNum).toFixed(2) }}</span>
                    </div>
                    <!-- 直接触发 sku 内部事件，通过内部事件执行 onBuyClicked 回调 -->
                    <van-button square size="large" type="danger" @click="beforePay">
                        立即购买
                    </van-button>
                </div>
                <explore-service ref="exploreService" @confirmRule="props.skuEventBus.$emit('sku:buy')"></explore-service>
            </template>
        </van-sku>


<!--        <van-popup v-model="show" closeable round position="bottom">-->
<!--            <van-cell-group style="margin: 40px 0 100px;">-->
<!--                <van-cell title="服务费单价">-->
<!--                    <template #default>-->
<!--                        <div class="van-sku__goods-price">-->
<!--                            <span class="van-sku__price-num" style="float: right;">{{price/100.00}}</span>-->
<!--                            <span class="van-sku__price-symbol" style="float: right;">￥</span>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </van-cell>-->
<!--                <van-cell title="购买数量">-->
<!--                    <template #default>-->
<!--                        <van-stepper v-model="number" min="1" integer/>-->
<!--                    </template>-->
<!--                </van-cell>-->
<!--            </van-cell-group>-->
<!--            <div class="van-sku-actions">-->
<!--                <span style="display: inline-block; line-height: 41px; min-width: 40px; color: rgb(238, 10, 36); font-size: 15px;">合计:</span>-->
<!--                <div class="van-sku__goods-price" style="line-height: 40px; margin-right: 16px;">-->
<!--                    <span class="van-sku__price-num">￥{{(price * number)/100.00}}</span>-->
<!--                </div>-->
<!--                <button class="van-button van-button&#45;&#45;danger van-button&#45;&#45;large van-button&#45;&#45;square"  @click="beforePay">-->
<!--                    <span class="van-button__text"> 立即购买 </span>-->
<!--                </button>-->
<!--            </div>-->
<!--        </van-popup>-->
        <phone-bind ref="phoneBind" @success="bindPhoneSuccess"></phone-bind>
    </div>
</template>

<script>
    import {Dialog, Notify, Toast} from "vant";
    import PhoneBind from "../Common/Dialog/PhoneBind";
    import ExploreService from "../Common/Dialog/ExploreService";
    import wx from "weixin-js-sdk";

    export default {
        name: "LotteryBuy",
        data() {
            return {
                number: 1,
                show: false,
                shop: false,
                shop_types:[],
                goods: false,
                sku: {
                    // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
                    // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
                    tree: [
                        {
                            k: '店铺类型', // skuKeyName：规格类目名称
                            v: [{id: '1', name: '',}],
                            k_s: 's1' // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
                        }
                    ],
                    // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
                    list: [

                    ],
                    price: '0.00', // 默认价格（单位元）
                    collection_id: 1, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
                    none_sku: false, // 是否无规格商品
                },
                good: {
                    // 商品标题
                    title: '测试商品',
                },
                initialSku: { // 初始选中对象
                    s1: 1,
                    selectedNum: 1,// 初始选中数量
                    selectedProp: {1: [1]}// 初始选中的商品属性
                },
                asset: require('../../assets/img/buy_start.png'),
                title: require('../../assets/img/buy_title.png'),
                verified_phone:false,
                verified_shop:false,
            }
        },
        components: {
            PhoneBind,
            ExploreService,
        },
        mounted() {
            this.post('/api/client/activity/buy/before').then(result => {
                if (result.code === 0) {
                    this.verified_phone = result.data.verified_phone;
                    this.verified_shop = result.data.verified_shop;
                    this.shop = result.data.shop;
                    this.goods = result.data.goods;
                    this.sku.tree[0].v = result.data.type;
                    this.shop_types = result.data.type;
                    this.sku.list = result.data.list;
                }
            });
        },
        methods: {
            buyBefore() {
                Dialog.alert({
                    title:'提示',
                    message:'暂时不允许购买',
                });
                return;
                // 检查当前用户是否绑定了手机号
                if (!this.verified_phone) {
                    this.$refs.phoneBind.init();
                    return;
                }
                if (!this.shop) {
                    Dialog.alert({
                        title: '提示',
                        message: '您还未绑定店铺信息，是否前往绑定？',
                        showCancelButton: true,
                    }).then(() => {
                        this.$router.push('/shop');
                    });
                    return;
                }
                if (!this.goods) {
                    Dialog.alert({
                        title: '提示',
                        message: '您还未录入霸王餐活动策略，是否前往绑定？',
                        showCancelButton: true,
                    }).then(() => {
                        this.$router.push('/shop');
                    });
                    return;
                }
                if (!this.shop_types.length){
                    Dialog.alert({
                        title: '提示',
                        message: '您还没有认证通过的店铺，是否前往绑定？',
                        showCancelButton: true,
                    }).then(() => {
                        this.$router.push('/shop');
                    });
                    return;
                }
                this.show = true;
            },
            bindPhoneSuccess() {
                this.verified_phone = true;
                Notify('手机号绑定成功');
                this.buyBefore();
            },

            onBuyClicked(sku) {
                let that = this;
                console.log({
                    num:sku.selectedNum,
                    st_id:sku.selectedSkuComb.id,
                    price:sku.selectedSkuComb.price,
                });
                this.post('/api/client/activity/buy',{
                    num:sku.selectedNum,
                    st_id:sku.selectedSkuComb.id,
                    price:sku.selectedSkuComb.price,
                }).then(function(result){
                    if(result.code === 0){
                        // 订单生成成功，支付
                        wx.chooseWXPay({
                            timestamp: result.data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: result.data.nonceStr, // 支付签名随机串，不长于 32 位
                            package: result.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: result.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: result.data.paySign, // 支付签名
                            success: function (res) { // 支付成功后的回调函数
                                that.show = false;
                                Dialog.confirm({
                                    title: '购买成功',
                                    message: '您已购买成功，是否立即使用？',
                                    confirmButtonText:'立即使用',
                                    cancelButtonText:'返回',
                                }).then(() => {
                                    // 立即使用
                                    that.$router.push('/lottery/apply');
                                });
                            }
                        });
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },


            beforePay(){
                if (this.verified_shop) {
                    this.$refs.exploreService.init(2);
                } else {
                    this.$dialog.alert({
                        title: '提示',
                        message: '很抱歉，您还没有资格审核通过的店铺,不建议您直接购买。',
                    });
                }
            },
        }
    }
</script>

<style scoped>
    .progress p {
        display: block;
        padding: 5px 11px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 15px;
        border-radius: 15px;
        font-weight: bold;
        background: rgb(222, 218, 235);
    }
</style>