<template>
    <van-action-sheet v-model="show">
        <p style="font-size:16px;text-align:center;font-weight:bold;">为保障您的权益，请验证手机号</p>
        <van-cell-group>
            <van-field v-model="phoneNumber" label="手机号" placeholder="请输入手机号" :error-message="errMessage" />
            <van-field v-model="code" center clearable label="短信验证码" placeholder="请输入短信验证码">
                <van-button slot="button" v-show="!counting" size="small" color="linear-gradient(to top, #e4c491, #e3ac5b)" type="primary" @click="sendMessage">发送验证码</van-button>
                <van-button slot="button" v-show="counting" disabled size="small">
                    <van-count-down ref="countDown" :auto-start="false" :time="60 * 1000" format="sss"  @finish="finish"/>
                </van-button>
            </van-field>
            <div style="margin:0 auto;">
                <van-button size="small" style="display:block;width:40%;margin:25px auto;" color="linear-gradient(to top, #e4c491, #e3ac5b)" @click="success">确定</van-button>
            </div>
        </van-cell-group>
    </van-action-sheet>
</template>

<script>
    import {Toast} from 'vant';
    export default {
        name: "PhoneBind",
        data(){
            return {
                show: false,
                phoneNumber: '', // 手机号
                code: '', // 短信验证码
                counting: false,
                errMessage:'',
                phoneNumberCheck: false,
                permitToPay: false,
            }
        },
        watch:{
            phoneNumber(newValue){
                if(this.phoneNumberCheck){
                    if(newValue.length === 11){
                        this.errMessage = '';
                    } else {
                        this.errMessage = '手机号格式错误';
                    }
                }
            }
        },
        methods:{
            init(){
                this.permitToPay = false;
                this.show = true;
            },
            // 发送验证短信
            sendMessage(){
                // 验证手机号是否已填写
                if(this.phoneNumber.length !== 11){
                    this.phoneNumberCheck = true;
                    this.errMessage = '手机号格式错误';
                    Toast('请填写正确的手机号');
                    return;
                }
                if( this.errMessage ){
                    Toast('请填写正确的手机号');
                    return;
                }
                // 验证通过，发送短信
                this.post('/api/client/common/message/send',{phone:this.phoneNumber}).then(result=>{
                    if(result.code === 0){
                        Toast.success(result.msg);
                        this.permitToPay = true;
                        this.counting = true;
                        this.$refs.countDown.start();
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },
            // 倒计时结束
            finish(){
                this.counting = false;
                this.$refs.countDown.reset();
            },
            success(){
                // 验证验证码是否正确输入
                if(!this.permitToPay){
                    Toast('请先发送验证码');
                    return;
                }
                if(!(this.code && this.code.length === 6)){
                    Toast('请输入6位验证码');
                    return;
                }
                this.post('/api/client/common/message/check',{code:this.code}).then(result=>{
                    if(result.code === 0){
                        this.show = false;
                        this.$emit('success');
                    } else {
                        Toast(result.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>